import React, { useEffect, useLayoutEffect, useState } from "react";
import Layout from "../../layouts/layout";
import { useAddressStore } from "../../store/address";
import Select, { SingleValue } from "react-select";
import axios from "axios";
import {
    getvoiebyid,
    urlGetAddressById,
    urlGetCommune,
    urlGetNumero,
    urlGetVoie,
    saveDmdCertificat,
} from "../../api/api";
import { Input } from "react-daisyui";
import { debounce } from "lodash";
import { useMapStore } from "../../store/map";
import { toast } from "react-toastify";
import PopupDemande from "../../components/popup/popupdemande";
import MultipleUploadFile from "../../components/fileupload/multipleuploadfile";
import Map from "../../components/services/address/map/map";
import Captcha from "../../components/captcha/captcha";

const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight - 246]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
};

interface optionInterface {
    value: string;
    label: string;
}

interface stateInterface {
    optionsCommune: optionInterface[] | [];
    optionsRue: optionInterface[] | [];
    optionsNumero: optionInterface[] | [];
    bDisableComboCommune: boolean;
    idLocalite: string | null;
    idNumero: string | null;
    idRue: string | null;
    nomOrg: string;
    nom: string;
    prenom: string;
    email: string;
    fileupload: any[];
}
interface communeInterface {
    id_localite: number;
    nom: string;
}
interface rueInterface {
    id_voie: number;
    alias: string;
}

interface numeroInterface {
    id_numero: string;
    numero: string;
}

function noOptionsMessage() {
    return "Aucune donnée";
}

const Demande = () => {
    const {
        setMapBounds,
        setSelectedAddresses,
        setCoordonneex,
        setCoordonneey,
    } = useMapStore();

    const [modalShow, setModalShow] = useState<boolean>(false);
    const {
        setResults: setResultAddresses,
        infoFeatures,
        setInfoFeatures,
        file,
    } = useAddressStore();
    const [localite, setCommune] = useState<string>("");
    const [numero, setNumero] = useState<string>("");
    const [rue, setRue] = useState<string>("");
    const [choiceLocalite, setChoiceLocalite] = useState<string>("");
    const [choiceNumero, setChoiceNumero] = useState<string>("");
    const [choiceRue, setChoiceRue] = useState<string>("");
    const [idNum, setIdNum] = useState<string>("");
    const [nameRue, setNameRue] = useState<string>("");
    const [labelName, setLabelName] = useState("");
    const initialState = {
        optionsCommune: [],
        optionsRue: [],
        optionsNumero: [],
        bDisableComboCommune: true,
        idLocalite: null,
        idRue: null,
        idNumero: null,
        nomOrg: "",
        nom: "",
        prenom: "",
        email: "",
        fileupload: [],
    };
    const [state, setState] = useState<stateInterface>(initialState);
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);
    const [reloadCaptcha, setReloadCaptcha] = useState(false);
    const minimumChar = process.env.MIN_CHAR_TO_SEARCH;

    const {
        optionsCommune,
        optionsRue,
        optionsNumero,
        idLocalite,
        idRue,
        idNumero,
        bDisableComboCommune,
        nomOrg,
        nom,
        prenom,
        email,
        fileupload,
    } = state;

    const [showhide, setShowhide] = React.useState<string>("");
    const [formErrors, setFormErrors] = useState<stateInterface>(initialState);
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const [isAutoComplete, setIsAutoComplete] = useState<boolean>(false);
    const [feature, setFeature] = useState<any>();
    const [resetFiles, setResetFiles] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false)

    const date = new Date();

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const currentDate = `${year}-${month}-${day}`;

    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
    } as Intl.DateTimeFormatOptions;

    const dateText = new Intl.DateTimeFormat("fr-FR", options).format(date);

    const handleshowhide = (event: React.FormEvent<EventTarget>) => {
        const getuser = (event.target as HTMLInputElement).value;
        const nomOrg = "";
        setState((prevState) => ({
            ...prevState,
            nomOrg,
        }));
        setShowhide(getuser);
    };

    //77777777777777777777777777777777777777777777777777777777777777777777
    const handleChangeform = (e: any) => {
        const { name, value } = e.target;

        if (showhide === "Personne morale") {
            if (state.nomOrg) {
                document.getElementById("nomOrg")!.style.border =
                    "1px solid #D5D5D6";
            }
        }
        if (name == "nom") {
            document.getElementById("nom")!.style.border = "1px solid #D5D5D6";
        }
        if (name == "prenom") {
            document.getElementById("prenom")!.style.border =
                "1px solid #D5D5D6";
        }
        if (name == "email") {
            document.getElementById("email")!.style.border =
                "1px solid #D5D5D6";
        }

        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        // handleSubmit(e)
    };

    useEffect(() => {
        if (showhide === "Personne morale") {
            if (state.nomOrg) {
                document.getElementById("nomOrg")!.style.border =
                    "1px solid #D5D5D6";
            }
        }
        if (state.nom) {
            document.getElementById("nom")!.style.border = "1px solid #D5D5D6";
        }
        if (state.prenom) {
            document.getElementById("prenom")!.style.border =
                "1px solid #D5D5D6";
        }
        if (state.email) {
            document.getElementById("email")!.style.border =
                "1px solid #D5D5D6";
        }
    }, [state]);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setFormErrors(validate(state));
        if (isCaptchaValid) {
            setIsSubmit(true);
        } else {
            toast.error("Valeur captcha incorrecte, veuillez rééssayer!", {
                position: "top-right",
                autoClose: 4000,
            });
            setReloadCaptcha(true);
        }
    };

    // useEffect(() => {
    //   console.log('>>>>>>>>>>>>>>>>>>>>>>>',formErrors);
    //   if (Object.keys(formErrors).length === 0 && isSubmit) {
    //     console.log('*****************',state);
    //   }
    // }, [formErrors]);

    const validate = (values: any) => {
        const errors = initialState;
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (showhide === "Personne morale") {
            if (!values.nomOrg) {
                document.getElementById("nomOrg")!.style.border =
                    "1px solid red";
            }
        }
        if (!values.nom) {
            document.getElementById("nom")!.style.border = "1px solid red";
        }
        if (!values.prenom) {
            document.getElementById("prenom")!.style.border = "1px solid red";
        }
        if (!values.email) {
            document.getElementById("email")!.style.border = "1px solid red";
        } else if (!regex.test(values.email)) {
            errors.email = "Email invalide!";
        }

        return errors;
    };

    useEffect(() => {
        return () => {
            setInfoFeatures([]);
        };
    }, []);

    //77777777777777777777777777777777777777777777777777777777777777777777

    //VAOVAO CODE

    useEffect(() => {
        if (!feature) return;

        const idVoie: string = feature.id_voie;
        axios
            .get(getvoiebyid(idVoie))
            .then((response: any) => {
                let newInfoFeatures: any = {};

                if (response.data.success !== false) {
                    const nameVoie = response.data.alias;
                    newInfoFeatures = {
                        id_numero: feature.id_numero,
                        id_localite: feature.id_localite,
                        id_voie: feature.id_voie,
                        numero: feature.numero,
                        alias: nameVoie,
                    };
                    setCommune(newInfoFeatures.id_localite);
                    setNumero(newInfoFeatures.numero);
                    setRue(newInfoFeatures.id_voie);
                    setIdNum(newInfoFeatures.id_numero);
                    setNameRue(newInfoFeatures.alias);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        setCoordonneex(feature.x);
        setCoordonneey(feature.y);
    }, [feature]);

    //888888888888888888888888888888888888888888888888888888888888888888888
    useEffect(() => {
        if (infoFeatures.length > 0) {
            infoFeatures.map((item: any) => {
                setCommune(item.id_localite);
                setNumero(item.numero);
                setRue(item.id_voie);
                setIdNum(item.id_numero);
                setNameRue(item.alias);
            });
        } else {
            return;
        }
    }, [infoFeatures]);

    useEffect(() => {
        if (!numero || !rue || !localite) return;
        setIsAutoComplete(true);
    }, [numero, localite, rue]);

    // useEffect(() => {
    //   const idLocalite = localite;
    //   const idRue = rue;
    //   const idNumero = numero;
    //   setState((prevState) => ({
    //     ...prevState,
    //     idLocalite,
    //     idRue,
    //     idNumero,

    //   }));
    // },[idLocalite,idRue,idNumero])

    useEffect(() => {
        axios
            .get(urlGetCommune())
            .then((response) => {
                let data = response.data;

                let optionsCommune = [] as optionInterface[];
                if (data.success !== false) {
                    optionsCommune = response.data?.map(
                        (localite: communeInterface) => ({
                            value: localite.id_localite,
                            label: localite.nom,
                        })
                    );
                }

                setState((prevState) => ({
                    ...prevState,
                    optionsCommune,
                    bDisableComboCommune: false,
                    idLocalite: null,
                    optionsRue: [],
                    optionsNumero: [],
                    idRue: null,
                    idNumero: null,
                }));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const communeValue: any = optionsCommune.filter(
        (option) => option.value === localite
    );

    const label: string = communeValue.map((item: any) => {
        return item.label;
    });

    useEffect(() => {
        setLabelName(label[0]);
    }, [label]);

    // const {
    //   control,
    //   register,
    //   handleSubmit,
    //   reset,
    //   formState: { errors },
    // } = useForm<InfoDemande>();

    const [width, height] = useWindowSize();

    if (width == 0 || height == 0) return null;

    // const onSubmit = handleSubmit((data: Object, e: any) => {
    //   e.preventDefault();
    //   setRue('')
    //   setNumero('')
    //   setCommune('')
    //   setState((prev) => ({...prev, optionsCommune: []}))

    //   setShowToast(true);
    //   reset();
    // });

    const retourPage = (e: any) => {
        e.preventDefault();
        setInfoFeatures([]);
        history.back();
    };

    const onChangeCommune = async (value: SingleValue<optionInterface>) => {
        const idLocalite = value ? value.value : null;
        const nomLocalite = value ? value.label : "";
        setInfoFeatures([]);

        setChoiceLocalite(nomLocalite);

        setState((prevState) => ({
            ...prevState,
            idLocalite,
        }));
    };

    const onChangeRue = (value: SingleValue<optionInterface>) => {
        const idRue = value ? value.value : null;
        const nomRue = value ? value.label : "";

        setChoiceRue(nomRue);

        setState((prevState) => ({
            ...prevState,
            idRue,
            optionsNumero: [],
            idNumero: null,
        }));
    };

    const onInputRueChange = debounce((newValue: string) => {
        if (newValue.trim().length >= parseInt(minimumChar as string)) {
            axios
                .get(
                    urlGetVoie({
                        id_localite: state.idLocalite!,
                        alias: newValue,
                    })
                )
                .then((response) => {
                    let data = response.data;

                    let optionsRue = [] as optionInterface[];
                    if (data.success !== false) {
                        optionsRue = response.data.map((rue: rueInterface) => ({
                            value: rue.id_voie,
                            label: rue.alias,
                        }));
                    }

                    setState((prevState) => ({
                        ...prevState,
                        optionsRue,
                        optionsNumero: [],
                        idRue: null,
                        idNumero: null,
                    }));
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, 500);

    const onInputNumeroChange = debounce((newValue: string) => {
        if (newValue.trim().length >= parseInt(minimumChar as string)) {
            axios
                .get(
                    urlGetNumero({
                        id_voie: state.idRue!,
                        numero: newValue,
                    })
                )
                .then((response) => {
                    let data = response.data;

                    let optionsNumero = [] as optionInterface[];
                    if (data.success !== false) {
                        optionsNumero = response.data.map(
                            (rue: numeroInterface) => ({
                                value: rue.id_numero,
                                label: rue.numero,
                            })
                        );
                    }

                    setState((prevState) => ({
                        ...prevState,
                        optionsNumero,
                        idNumero: null,
                    }));
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, 500);

    const onChangeNumero = (value: SingleValue<optionInterface>) => {
        const idNumero = value ? value.value : null;
        const numero = value ? value.label : "";

        setChoiceNumero(numero);

        setState((prevState) => ({
            ...prevState,
            idNumero,
        }));

        if (idNumero) {
            axios
                .get(urlGetAddressById(idNumero))
                .then((response) => {
                    let data = response.data;
                    if (data.success !== false) {
                        setSelectedAddresses([idNumero]);
                        setResultAddresses([data]);
                    }
                })
                .catch((error) => {});
        }
    };

    const handleChange = (event: any) => {
        console.log(event.target.value);
    };

    const handleSave = (event: any) => {
        event.preventDefault();

        const files: any[] = [];

        file.length > 0
            ? file.map((item: any) => {
                  files.push(item);
              })
            : console.log("No file");

        setIsLoading(true)

        saveDmdCertificat({
            organisme: state.nomOrg,
            nom: state.nom,
            prenom: state.prenom,
            email: state.email,
            localite: choiceLocalite,
            rue: choiceRue,
            numero: choiceNumero,
            files: files,
            date: currentDate,
        })
            .then((res) => {
                let data = res.data;
                if (data.success) {
                    setRue("");
                    setNumero("");
                    setCommune("");
                    setIsAutoComplete(false);
                    setResetFiles(true);
                    setTimeout(() => {
                        setResetFiles(false);
                    }, 1000);

                    setState((prev) => ({
                        ...prev,
                        nom: "",
                        prenom: "",
                        email: "",
                        nomOrg: "",
                        idLocalite: "",
                        idRue: "",
                        idNumero: "",
                    }));

                    toast.info(`L'enregistrement a été effectué avec succès sous le N° ${data.numero_demande}.`, {
                        position: "top-right",
                        autoClose: 4000,
                    });

                    setModalShow(false);
                    console.log(data);
                } else if (data.msg == "limit") {
                    setCoordonneey("");
                    setCoordonneex("");
                    setState(initialState);

                    toast.error(
                        "Votre demande ne peut pas être enregistrée car vous avez déjà fait une demande que nous avons prise en compte",
                        {
                            position: "top-right",
                            autoClose: 4000,
                        }
                    );

                    setModalShow(false);
                } else {
                    toast.error("L'enregistrement n'a pas abouti !", {
                        position: "top-right",
                        autoClose: 4000,
                    });

                    console.log("No data found : " + data);
                }
                setIsLoading(false)
            })
            .catch((error: any) => {
                console.log(error);
            });

        // console.log("new state", state);
    };

    const handleCaptchaValidation = (valid: any) => {
        setIsCaptchaValid(valid);
    };

    //APPEL MODAL RECAPITULATIF
    const showModal = (e: any) => {
        e.preventDefault();

        if (isAutoComplete) {
            const idNumero = idNum;
            const idLocalite = localite;
            const idRue = rue;
            const files = file;

            setState((prev) => ({
                ...prev,
                idLocalite,
                idRue,
                idNumero,
                files,
            }));
        }

        if (
            showhide === "Personne morale"
                ? !state.nomOrg
                : "" ||
                  !state.nom ||
                  !state.prenom ||
                  !state.email ||
                  !isCaptchaValid ||
                  state.idLocalite == null ||
                  state.idRue == null ||
                  state.idNumero == null
        ) {
            handleSubmit(e);
        } else {
            handleSubmit(e);
            setModalShow(true);
            setReloadCaptcha(true);
        }
        setTimeout(() => {
            setReloadCaptcha(false);
        }, 1000);
    };

    const divStyles = {
        boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
        marginTop: "-6px",
        height: height / 1.15 + "px",
    };

    const style = {
        height: width >= 1495 ? height + 63 + "px" : "",
        width: "100%",
    };

    return (
        <Layout>
            <div
                className="tw-flex tw-justify-center tw-items-center"
                style={style}
            >
                <div className="tw-w-full">
                    <h3 className="tw-text-center tw-flex tw-justify-center tw-items-center tw-pb-10">
                        Formulaire de demande de certificat
                    </h3>
                    <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-3">
                        <form
                            // onSubmit={onSubmit}
                            className="tw-flex tw-flex-col tw-space-y-5 tw-items-center tw-ml-0 tw-mb-7 tw-px-7 lg:tw-px-0 lg:tw-mb-0 lg:tw-ml-7 "
                        >
                            <label className="tw-w-full tw-max-w-xl tw-text-center tw-text-xl tw-font-bold tw-pb-2">
                                Demande de certificat
                            </label>
                            <div className="tw-flex tw-space-x-2 tw-w-full tw-max-w-xl">
                                <select
                                    name="type_personne"
                                    defaultValue={"Personne physique"}
                                    onChange={(e) => handleshowhide(e)}
                                    className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                >
                                    <option value="Personne physique">
                                        Personne physique
                                    </option>
                                    <option value="Personne morale">
                                        Personne morale
                                    </option>
                                </select>
                                <div className="tw-w-full tw-max-w-xs">
                                    <Input
                                        className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                        placeholder="Date"
                                        value={dateText}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            {labelName || rue || numero ? (
                                <>
                                    <Input
                                        className="tw-pl-3 tw-border-[1px] tw-text-[#000] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                        placeholder="Localité"
                                        value={labelName}
                                        onChange={(e) => handleChange(e)}
                                        disabled={true}
                                    />
                                    <div className="tw-flex tw-w-full tw-max-w-xl tw-space-x-2">
                                        <Input
                                            className="tw-pl-3 tw-border-[1px] tw-text-[#000] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] focus:tw-outline-[#2684FF] tw-w-6/12 tw-max-w-xl"
                                            placeholder="Voie"
                                            value={nameRue}
                                            disabled={true}
                                        />
                                        <Input
                                            className="tw-pl-3 tw-border-[1px] tw-text-[#000] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] focus:tw-outline-[#2684FF] tw-w-6/12 tw-max-w-xl"
                                            placeholder="N°"
                                            value={numero}
                                            disabled={true}
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Select
                                        className=" tw-w-full tw-max-w-xl tw-text-[#808080]"
                                        placeholder="Localité"
                                        options={optionsCommune}
                                        value={optionsCommune.filter(
                                            (option) =>
                                                option.value === idLocalite
                                        )}
                                        onChange={onChangeCommune}
                                        noOptionsMessage={noOptionsMessage}
                                        isClearable={true}
                                    />
                                    <div className="tw-flex tw-w-full tw-max-w-xl tw-space-x-2">
                                        <Select
                                            className=" tw-w-6/12 tw-max-w-xl tw-text-[#808080]"
                                            placeholder="Rue"
                                            onChange={onChangeRue}
                                            options={optionsRue}
                                            value={optionsRue.filter(
                                                (option) =>
                                                    option.value == idRue
                                            )}
                                            onInputChange={onInputRueChange}
                                            noOptionsMessage={noOptionsMessage}
                                            isClearable={true}
                                            isDisabled={
                                                state.idLocalite === null
                                            }
                                        />
                                        <Select
                                            className=" tw-w-6/12 tw-max-w-xl tw-text-[#808080]"
                                            placeholder="N°"
                                            options={optionsNumero}
                                            value={optionsNumero.filter(
                                                (option) =>
                                                    option.value == idNumero
                                            )}
                                            noOptionsMessage={noOptionsMessage}
                                            onInputChange={onInputNumeroChange}
                                            onChange={onChangeNumero}
                                            isDisabled={state.idRue === null}
                                            isClearable={true}
                                        />
                                    </div>
                                </>
                            )}

                            {showhide === "Personne morale" ? (
                                <>
                                    <div className="tw-w-full tw-max-w-xl">
                                        <Input
                                            className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                            placeholder="Nom de l'organisme"
                                            onChange={handleChangeform}
                                            value={nomOrg}
                                            name="nomOrg"
                                            id="nomOrg"
                                        />
                                        <p className="tw-text-[#FF0303] tw-text-xs">
                                            {formErrors.nomOrg}
                                        </p>
                                    </div>

                                    <div className="tw-w-full tw-max-w-xl">
                                        <Input
                                            className="tw-pl-3 tw-border-[1px] tw-text-[#000] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                            placeholder="Nom du représentant"
                                            onChange={handleChangeform}
                                            value={nom}
                                            name="nom"
                                            id="nom"
                                        />
                                        <p className="tw-text-[#FF0303] tw-text-xs">
                                            {formErrors.nom}
                                        </p>
                                    </div>

                                    <div className="tw-w-full tw-max-w-xl">
                                        <Input
                                            className="tw-pl-3 tw-border-[1px] tw-text-[#000] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                            placeholder="Prénom du représentant"
                                            onChange={handleChangeform}
                                            value={prenom}
                                            name="prenom"
                                            id="prenom"
                                        />
                                        <p className="tw-text-[#FF0303] tw-text-xs">
                                            {formErrors.prenom}
                                        </p>
                                    </div>

                                    <div className="tw-w-full tw-max-w-xl">
                                        <Input
                                            className="tw-pl-3 tw-border-[1px] tw-text-[#000] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                            placeholder="Email"
                                            onChange={handleChangeform}
                                            value={email}
                                            name="email"
                                            id="email"
                                        />
                                        <p className="tw-text-[#FF0303] tw-text-xs">
                                            {formErrors.email}
                                        </p>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="tw-w-full tw-max-w-xl">
                                        <Input
                                            className="tw-pl-3 tw-border-[1px] tw-text-[#000] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                            placeholder="Nom"
                                            onChange={handleChangeform}
                                            value={nom}
                                            name="nom"
                                            id="nom"
                                        />
                                        <p className="tw-text-[#FF0303] tw-text-xs">
                                            {formErrors.nom}
                                        </p>
                                    </div>

                                    <div className="tw-w-full tw-max-w-xl">
                                        <Input
                                            className="tw-pl-3 tw-border-[1px] tw-text-[#000] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                            placeholder="Prénom"
                                            onChange={handleChangeform}
                                            value={prenom}
                                            name="prenom"
                                            id="prenom"
                                        />
                                        <p className="tw-text-[#FF0303] tw-text-xs">
                                            {formErrors.prenom}
                                        </p>
                                    </div>

                                    <div className="tw-w-full tw-max-w-xl">
                                        <Input
                                            className="tw-pl-3 tw-border-[1px] tw-text-[#000] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                            placeholder="Email"
                                            onChange={handleChangeform}
                                            value={email}
                                            name="email"
                                            id="email"
                                        />
                                        <p className="tw-text-[#FF0303] tw-text-xs">
                                            {formErrors.email}
                                        </p>
                                    </div>
                                </>
                            )}

                            <Captcha
                                onCaptchaValidation={handleCaptchaValidation}
                                reload={reloadCaptcha}
                            />

                            <div className="tw-flex tw-justify-around tw-w-full tw-max-w-xl tw-mt-2 tw-space-x-2">
                                <button
                                    onClick={(e) => retourPage(e)}
                                    className="tw-btn tw-btn-sm tw-max-w-xl tw-font-normal tw-w-[47%] tw-rounded-lg tw-text-white tw-bg-secondary hover:tw-bg-secondary_hover"
                                >
                                    Annuler
                                </button>
                                <button
                                    onClick={(e) => showModal(e)}
                                    className="tw-btn tw-btn-sm tw-max-w-xs tw-font-normal tw-w-[47%] tw-rounded-lg tw-text-white tw-bg-secondary hover:tw-bg-secondary_hover"
                                >
                                    Enregistrer
                                </button>
                            </div>
                        </form>

                        <div className="tw-flex tw-justify-center tw-w-full tw-mb-7 lg:tw-mb-0">
                            <div className="tw-w-full tw-px-4 tw-max-w-xl">
                                <MultipleUploadFile
                                    title="Vous avez la possibilité de rajouter des fichiers complémentaires ici"
                                    resetFiles={resetFiles}
                                />
                            </div>
                        </div>

                        <div className="tw-mt-0 tw-w-full tw-pl-7 tw-mb-7 tw-pr-7 lg-tw-mb-0 lg:tw-pl-0 lg:tw-mt-[-37px]">
                            <div className="tw-mb-[1.1rem] tw-text-center">
                                <p className="tw-font-bold">
                                    Carte de localisation
                                </p>
                            </div>
                            <div
                                style={divStyles}
                                className="tw-flex tw-w-full tw-rounded-md tw-items-center"
                            >
                                <Map
                                    bNewDemande={false}
                                    setErrorFeature={setFeature}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PopupDemande
                show={modalShow}
                onHide={() => setModalShow(false)}
                handleSave={handleSave}
                nom={nom}
                prenom={prenom}
                email={email}
                localite={optionsCommune}
                rue={optionsRue}
                numero={optionsNumero}
                oneNum={numero}
                nameRue={nameRue}
                idLocalite={isAutoComplete ? localite : idLocalite}
                idRue={idRue}
                idNum={isAutoComplete ? idNum : idNumero}
                fileupload={file}
                nomOrg={nomOrg}
                currentDate={dateText}
                isAutoComplete={isAutoComplete}
                isLoading={isLoading}
            />
        </Layout>
    );
};

export default Demande;
