import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

import MoonLoader from "react-spinners/MoonLoader";

interface optionInterface {
    value: string;
    label: string;
}

interface MyPopup {
    show: boolean;
    onHide: any;
    handleSave: any;
    nom: string;
    prenom: string;
    email: string;
    localite: optionInterface[] | [];
    rue: optionInterface[] | [];
    numero: optionInterface[] | [];
    oneNum: string;
    idLocalite: any;
    idRue: any;
    idNum: any;
    nomOrg?: string;
    isAutoComplete: boolean;
    nameRue: string;
    fileupload: any[];
    currentDate: string;
    isLoading: boolean;
}

const PopupDemande: React.FC<MyPopup> = ({
    onHide,
    show,
    handleSave,
    nom,
    prenom,
    email,
    localite,
    rue,
    numero,
    oneNum,
    idNum,
    idLocalite,
    idRue,
    nomOrg,
    isAutoComplete,
    nameRue,
    fileupload,
    currentDate,
    isLoading,
}) => {
    // const [oRue, setORue] = useState()
    // const [oCommune, setOCommune] = useState()
    // const [oNumero, setONumero] = useState()

    // useEffect (() => {
    //    const r = rue.filter((option) => option.value == idRue)
    // }, [])

    const oRue = rue.filter((option) => option.value == idRue);
    const oCommune = localite.filter((option) => option.value == idLocalite);
    const oNumero = numero.filter((option) => option.value == idNum);

    return (
        <Modal
            className="tw-relative"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
        >
            {isLoading && (
                <div className="tw-absolute tw-flex tw-justify-center tw-items-center tw-h-full tw-w-full tw-left-0 tw-bg-white tw-opacity-50 tw-z-50">
                    <MoonLoader color="#21254C" size={150} />
                </div>
            )}
            <Modal.Header closeButton onClick={onHide}>
                <Modal.Title id="contained-modal-title-vcenter">
                    Récapitulatif de la demande du {currentDate}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflow: "auto" }}>
                <h4>Informations</h4>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            {nomOrg && <th>Organisme</th>}
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th>E-mail</th>
                            <th>Localité</th>
                            <th>Rue</th>
                            <th>Numéro</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {nomOrg && <td>{nomOrg}</td>}
                            <td>{nom ? nom : ""}</td>
                            <td>{prenom ? prenom : ""}</td>
                            <td>{email ? email : ""}</td>
                            <td>
                                {oCommune.length > 0 ? oCommune[0].label : ""}
                            </td>
                            <td>
                                {isAutoComplete
                                    ? nameRue
                                    : oRue.length > 0
                                    ? oRue[0].label
                                    : ""}
                            </td>
                            <td>
                                {isAutoComplete
                                    ? oneNum
                                    : oNumero.length > 0
                                    ? oNumero[0].label
                                    : ""}
                            </td>
                        </tr>
                    </tbody>
                </Table>
                {fileupload.length > 0 ? (
                    <>
                        <h4>Pièces justificatives</h4>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Nom du fichier</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fileupload.map((item: any) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>{item.file.name}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </>
                ) : (
                    <></>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={(e) => handleSave(e)}
                    style={{ background: "#ED6E19" }}
                >
                    Valider
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PopupDemande;
